<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <div>
      <button @click="getCountries">Get Countries</button>
      <input type='file' accept="image/*" @change="handleFileChange" ref="inputer" />
      <button @click="getFinalPhoto">getFinalPhoto</button>
    </div>

  </div>
</template>

<script>

// import { uploadPhoto } from '../util/aws'
import { uploadPhoto } from '../util/ali'

export default {
  name: 'HelloWorld',
  data: function() {
    return {
      photoKey: ""
    }
  },
  props: {
    msg: String
  },
  methods: {
    getCountries: async function() {
      const start = (new Date()).getTime()
      console.log("getCountries: ", start)
      var end = 0
      try {
        const res = await this.$axios({
          method: "POST",
          url: "https://api.getpassportphoto.online/stage/getCountries",
          data: {
            "country": "India"
          },
        })
        end = (new Date()).getTime()
        console.log("res: ", res)
      } catch (error) {
        end = (new Date()).getTime()
        console.log("error: ", error)
      }
      console.log("getCountries time: ", end-start)
    },
    handleFileChange: async function(event) {
      const start = (new Date()).getTime()
      console.log("upload: ", start)
      var end = 0
      const file = event.target.files[0]
      // 通过DOM取文件数据
      try {
        const res = await uploadPhoto(file)
        end = (new Date()).getTime()
        console.log('upload res: ', res)
        this.setData({
          photoKey: res.photoKey
        })
        this.parsePhoto(res.signedUrl, res.photoKey)
      } catch (error) {
        console.log('upload error: ', error)
        end = (new Date()).getTime()
      }
      console.log("upload time: ", end-start)
    },
    parsePhoto: async function(url, photoKey) {
      const start = (new Date()).getTime()
      console.log("parsePhoto: ", start)
      var end = 0
      try {
        const res = await this.$axios({
          method: "POST",
          url: "https://api.getpassportphoto.online/stage/parsePhoto",
          data: {
            url,
            photoKey,
            "width": 600,
            "height": 600,
            "background": "#ffffff"
          }
        })
        end = (new Date()).getTime()
        console.log("res: ", res)
      } catch (error) {
        end = (new Date()).getTime()
        console.log("error: ", error)
      }
      console.log("parsePhoto time: ", end-start)
    },
    getFinalPhoto: async function() {
      const start = (new Date()).getTime()
      console.log("getFinalPhoto: ", start)
      var end = 0
      const that = this
      try {
        const res = await this.$axios({
          method: "POST",
          url: "https://api.getpassportphoto.online/stage/getFinalPhoto",
          data: {
            orderId: "",
            photoKey: that.data.photoKey
          }
        })
        end = (new Date()).getTime()
        console.log("res: ", res)
      } catch (error) {
        end = (new Date()).getTime()
        console.log("error: ", error)
      }
      console.log("getFinalPhoto time: ", end-start)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
