const OSS = require('ali-oss')
import axios from "axios"

const stsUrl = "https://api.getpassportphoto.online/stage/aliSTS"

var ossClient

const uploadPhoto = async function(path) {
    var start = (new Date()).getTime()
    console.log("upload: ", start)
    var end = 0
    const time = new Date().getTime()
    const photoKey = `public/photos/${time}.jpg`
    var signedUrl = ''
    try {
        // 'object'填写上传至OSS的object名称,即不包括Bucket名称在内的Object的完整路径。
        // 'localfile'填写上传至OSS的本地文件完整路径。
        const ossClient = await getClient()
        end = (new Date()).getTime()
        console.log("getclienttime: ", end - start)
        await ossClient.put(photoKey, path)
        start = end
        end = (new Date()).getTime()
        console.log('put success: ', end - start)
        signedUrl = ossClient.signatureUrl(photoKey, { expires: 300 })
    } catch(e) {
        console.error('error: ', e)
    }
    return { photoKey, signedUrl }
}

const getClient = async () => {
    if (ossClient != undefined && ossClient != null) {
        return ossClient
    }
    // 设置客户端请求访问凭证的地址。
    const res = await axios.post(stsUrl)
    console.log("res: ", res)
    if (res.status != 200) {
        return
    }
    const data = res.data
    if (data == null || data.body == null || data.body.code != 0) {
        return
    }
    const token = data.body.data
    ossClient = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: token.region,
        accessKeyId: token.AccessKeyId,
        accessKeySecret: token.AccessKeySecret,
        stsToken: token.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: token.bucket,
        // 刷新临时访问凭证。
        refreshSTSToken: async () => {
            const refreshToken = await axios.post(stsUrl);
            return {
                accessKeyId: refreshToken.AccessKeyId,
                accessKeySecret: refreshToken.AccessKeySecret,
                stsToken: refreshToken.SecurityToken,
            };
        },
    })
    return ossClient
  };

export { uploadPhoto }
